var rootUrl = '';
var userAdmin = false;
var quoteSectionCount = 0;
var clientId = 0;

// code to stop losing changes in forms when navigating away from the page
var unsaved = false;

$(function () {
    rootUrl = $('body').data('root-url');
    userAdmin = $('body').data('user-admin') === 'True';
    var signaturePad;

    // alert user if navigating away from page but have changed form inputs
    if (userAdmin) {
        $(window).bind('beforeunload', function () {
            if (unsaved) {
                return "You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?";
            }
        });
        $('form.alert-unsaved-changes').on('change', ':input', function () { //triggers change in all input fields including text type
            unsaved = true;
        });
    }
    $('#clientDetailsModal').on('hide.bs.modal', function (e) {
        // reset navigate away from page check
        unsaved = false;
    });
    $('#projectDetailsModal').on('hide.bs.modal', function (e) {
        // reset navigate away from page check
        unsaved = false;
    });
    ///////////////////////////////////////////////////

    // handle selecting client and loading projects list
    $('#navbarClientDropdown .dropdown-menu a.select-client-btn').click(function (e) {
        e.preventDefault();
        var $this = $(this);
        var $toggle = $this.closest('.dropdown').find('.dropdown-toggle');

        $toggle.text($this.text());
        LoadNavProjectList($this.data('client-id'));
    });


    /// admin based functions - TODO move to new file and only load in for admin?
    $('#adminEditProjDesc').redactor({
        buttons: ['format', 'bold', 'italic', 'underline', 'deleted', 'lists', 'link', 'horizontalrule'],
        plugins: ['source'],
        script: false,
        overrideStyles: false,
        toolbarFixed: false,
        formatting: ['p', 'h1', 'h2', 'h3', 'h4']
    });

    $('#adminEditClientNotes').redactor({
        buttons: ['format', 'bold', 'italic', 'underline', 'deleted', 'lists', 'link', 'horizontalrule'],
        plugins: ['source'],
        script: false,
        overrideStyles: false,
        toolbarFixed: false,
        formatting: ['p', 'h1', 'h2', 'h3', 'h4']
    });

    // Add new project 
    $(document).on('click', '.admin-add-proj-btn', function (e) {
        e.preventDefault();
        $('#editProjectForm [name="Id"]').val('0'); // 0 used to indicate new project
        clientId = $(this).data('client-id');

        $.post(rootUrl + 'Project/GetProjectDefaults', {}, function (rsp) {
            quoteSectionCount = 0;
            $('#quoteSectionsContainer').empty(); // clear sections
            // quote & sections
            if (rsp.project.Quote) {
                for (i = 0; i < rsp.project.Quote.Sections.length; i++) {
                    console.log('Section: ' + rsp.project.Quote.Sections[i].Id);

                    $('#quoteSectionsContainer').append('<div id="adminQuoteSection-' + quoteSectionCount + '"></div>');
        
                    $('#adminQuoteSection-' + quoteSectionCount).loadTemplate($('#quoteSectionTemplate'), {
                        sectionId: quoteSectionCount,
                        Name: rsp.project.Quote.Sections[i].Name,
                        Summary: rsp.project.Quote.Sections[i].Summary,
                        Description: rsp.project.Quote.Sections[i].Description,
                        PriceType: rsp.project.Quote.Sections[i].PriceType,
                        Price: rsp.project.Quote.Sections[i].Price,
                        priceTypeName: 'PriceType-' + quoteSectionCount,
                        singleRadioId: 'SingleRadio' + quoteSectionCount,
                        monthlyRadioId: 'MonthlyRadio' + quoteSectionCount
                    });

                    // radios
                    $('#adminQuoteSection-' + quoteSectionCount + ' .edit-section-pricetype[value="' + rsp.project.Quote.Sections[i].PriceType + '"]').prop('checked',true);
                    
                    // apply editor
                    var textedit = $('#adminQuoteSection-' + quoteSectionCount + ' .admin-text-edit-dynamic').redactor({
                        buttons: ['format', 'bold', 'italic', 'underline', 'deleted', 'lists', 'link', 'horizontalrule'],
                        plugins: ['source'],
                        script: false,
                        overrideStyles: false,
                        toolbarFixed: false,
                        formatting: ['p', 'h1', 'h2', 'h3', 'h4']
                    });
                    textedit.redactor('code.set', rsp.project.Quote.Sections[i].Description);

                    // hide section - cannot do this in css redactor doesnt work if hidden
                    //$('#adminQuoteSection-' + quoteSectionCount + ' .admin-section-details-wrapper').hide();
                    quoteSectionCount++;
                }
            }

            $('#projectDetailsModal').modal({ backdrop: 'static' });
            //$('.admin-section-details-wrapper').hide();
        }, 'json');
    });

    // add new client
    $(document).on('click', '.edit-client-btn', function (e) {
        e.preventDefault();
        // reset form
        $('#editClientForm')[0].reset();
        $('#adminEditClientNotes').redactor('code.set', '');

        clientId = $(this).data('client-id');

        var $logoImagePreview = $('#editClientLogoImagePreview');
        var $logoImagePreviewContainer = $('#editClientLogoImagePreviewContainer');
        $logoImagePreview.attr('src', 'noimage');
        $logoImagePreviewContainer.hide();
        Base64ImageFilePicker($('#editClientLogoImageFile'), $('#editClientLogoImageBase64'), $logoImagePreview, $logoImagePreviewContainer);

        if (clientId === 0) {
            // new client
            $('#editClientForm [name="Id"]').val('0');
            $('#clientDetailsModal').modal({ backdrop: 'static' });
        }
        else {
            // edit client
            $.post(rootUrl + 'Clients/GetClientData', { id: clientId }, function (rsp) {
                $('#editClientForm [name="Id"]').val(rsp.client.Id);
                $('#editClientForm [name="Name"]').val(rsp.client.Name);
                $('#editClientForm [name="ContactName"]').val(rsp.client.ContactName);
                $('#editClientForm [name="Email"]').val(rsp.client.Email);
                $('#editClientForm [name="Phone"]').val(rsp.client.Phone);
                $('#editClientForm [name="SkypeId"]').val(rsp.client.SkypeId);
                $('#editClientForm [name="WebsiteUrl"]').val(rsp.client.WebsiteUrl);
                $('#editClientForm [name="Address1"]').val(rsp.client.Address1);
                $('#editClientForm [name="Address2"]').val(rsp.client.Address2);
                $('#editClientForm [name="TownCity"]').val(rsp.client.TownCity);
                $('#editClientForm [name="CountyState"]').val(rsp.client.CountyState);
                $('#editClientForm [name="PostcodeZip"]').val(rsp.client.PostcodeZip);
                $('#editClientForm [name="Country"]').val(rsp.client.CountryCode);
                
                if (rsp.client.LogoImageFile !== null && rsp.client.LogoImageFile !== '') {
                    $logoImagePreview.attr('src', 'Content/Clients/Logos/' + rsp.client.LogoImageFile);
                    $logoImagePreviewContainer.show();
                }

                $('#adminEditClientNotes').redactor('code.set', rsp.client.Notes);
                
                // show modal
                $('#clientDetailsModal').modal({ backdrop: 'static' });
            });
        }

        
        //console.log($(this).data("client-id"));
    });

    // add new quote section
    $(document).on('click', '.add-project-quote-section-btn', function (e) {
        e.preventDefault();
        $('#quoteSectionsContainer').append('<div id="adminQuoteSection-' + quoteSectionCount + '"></div>');
        
        $('#adminQuoteSection-' + quoteSectionCount).loadTemplate($("#quoteSectionTemplate"),
            {
                sectionId: quoteSectionCount,
                priceTypeName: "PriceType-" + quoteSectionCount,
                singleRadioId: "SingleRadio" + quoteSectionCount,
                monthlyRadioId: "MonthlyRadio" + quoteSectionCount
            });
        
        // apply editor
        $('#adminQuoteSection-' + quoteSectionCount + ' .admin-text-edit-dynamic').redactor({
            buttons: ['format', 'bold', 'italic', 'underline', 'deleted', 'lists', 'link', 'horizontalrule'],
            plugins: ['source'],
            script: false,
            overrideStyles: false,
            toolbarFixed: false,
            formatting: ['p', 'h1', 'h2', 'h3', 'h4']
        });

        quoteSectionCount++;
    }); 

    // edit project
    $(document).on('click', '.admin-edit-proj-btn', function (e) {
        e.preventDefault();
        // get project details
        var id = $(this).data("proj-id");
        $.post(rootUrl + 'Project/GetProjectData', { id: id }, function (rsp) {
            console.log(rsp);
            quoteSectionCount = 0;
            $('#quoteSectionsContainer').empty(); // clear sections
            clientId = rsp.project.ClientId;
            // set basic details
            $('#editProjectForm [name="Id"]').val(rsp.project.Id);
            $('#editProjectForm [name="Name"]').val(rsp.project.Name);
            $('#editProjectForm [name="Type"][value="' + rsp.project.Type + '"]').prop('checked',true);
            //$('#editProjectForm [name="Active"]').prop('checked');
            //$('#editProjectForm [name="Description"]').val(rsp.project.Description);
            $('#adminEditProjDesc').redactor('code.set', rsp.project.Description);
            // quote & sections
            console.log(rsp.project.Quote.length);
            if (rsp.project.Quote) {
                console.log(rsp.project.Quote.Sections.length);
                for (i = 0; i < rsp.project.Quote.Sections.length; i++) {
                    console.log("Section: " + rsp.project.Quote.Sections[i].Id);

                    $('#quoteSectionsContainer').append('<div id="adminQuoteSection-' + quoteSectionCount + '"></div>');
        
                    $('#adminQuoteSection-' + quoteSectionCount).loadTemplate($("#quoteSectionTemplate"), {
                        sectionId: quoteSectionCount,
                        Id: rsp.project.Quote.Sections[i].Id,
                        Name: rsp.project.Quote.Sections[i].Name,
                        Summary: rsp.project.Quote.Sections[i].Summary,
                        Description: rsp.project.Quote.Sections[i].Description,
                        PriceType: rsp.project.Quote.Sections[i].PriceType,
                        Price: rsp.project.Quote.Sections[i].Price,
                        priceTypeName: "PriceType-" + quoteSectionCount,
                        singleRadioId: "SingleRadio" + quoteSectionCount,
                        monthlyRadioId: "MonthlyRadio" + quoteSectionCount
                    });

                    // radios
                    $('#adminQuoteSection-' + quoteSectionCount + ' .edit-section-pricetype[value="' + rsp.project.Quote.Sections[i].PriceType + '"]').prop('checked',true);

                    console.log("text area: " + $('#adminQuoteSection-' + quoteSectionCount + ' .admin-text-edit-dynamic').length);
                    // apply editor
                    var textedit = $('#adminQuoteSection-' + quoteSectionCount + ' .admin-text-edit-dynamic').redactor({
                        buttons: ['format', 'bold', 'italic', 'underline', 'deleted', 'lists', 'link', 'horizontalrule'],
                        plugins: ['source'],
                        script: false,
                        overrideStyles: false,
                        toolbarFixed: false,
                        formatting: ['p', 'h1', 'h2', 'h3', 'h4']
                    });
                    textedit.redactor('code.set', rsp.project.Quote.Sections[i].Description);

                    // hide section - cannot do this in css redactor doesnt work if hidden
                    //$('#adminQuoteSection-' + quoteSectionCount + ' .admin-section-details-wrapper').hide();
                    quoteSectionCount++;
                }
            }

            $('#projectDetailsModal').modal({ backdrop: 'static' });
            //$('.admin-section-details-wrapper').hide();
        }, "json");

        
        //clientId = $(this).data('client-id');
    }); 

    // delete quote section
    $(document).on('click', '.delete-quote-section', function (e) {
        e.preventDefault();
        if (confirm("Delete section?")) {
            var id = $(this).data("section-id");
            var section = $('#adminQuoteSection-' + id);
            section.fadeOut().remove();
        }
    }); 

    // expand/collapse edit project sections
    $(document).on('click', '.admin-edit-section-collapse', function (e) {
        e.preventDefault();
        var btn = $(this);
        var container = btn.parents('.admin-quote-section');
        var details = container.find('.admin-section-details-wrapper');
        var isVisible = details.is(":visible");
        if (isVisible) {
            btn.addClass("collapsed");
        }
        else {
            btn.removeClass("collapsed");
        }
        details.slideToggle( "slow", function() {
            // Animation complete.
        });
    }); 

    // save project
    $('#editProjectForm').submit(function (e) {
        e.preventDefault();
        var project = new Object();
        var quote = new Object();
        var quoteSections = [];

        project.ClientId = clientId;
        project.Id = $('#editProjectForm [name="Id"]').val();
        project.Name = $('#editProjectForm [name="Name"]').val();
        project.Type = $('#editProjectForm [name="Type"]:checked').val();
        project.Active = $('#editProjectForm [name="Active"]').prop('checked');
        project.Description = $('#editProjectForm [name="Description"]').val();

        project.Quote = quote;
        quote.Sections = quoteSections;

        $('#quoteSectionsContainer .admin-quote-section').each(function () {
            var section = new Object();
            section.Id = $(this).find('.edit-section-id').val();
            section.Name = $(this).find('.edit-section-name').val();
            section.Summary = $(this).find('.edit-section-summary').val();
            section.Description = $(this).find('.edit-section-description').val();
            section.Price = $(this).find('.edit-section-price').val();
            section.PriceType = $(this).find('.edit-section-pricetype:checked').val();

            quoteSections.push(section);
        });
        //project.Quote.Sections = quoteSections;

        // post
        $.ajax({
            type: 'POST',
            url: rootUrl + 'Project/SaveProject',
            data: { project: project },
            dataType: 'json', //to parse string into JSON object,
            success: function (data) {
                if (data.result === 'ok') {
                    unsaved = false; // reset navigate away flag
                    // call done function in calling page
                    //$('#projectDetailsModal').modal('hide');
                    //LoadNavProjectList(clientId);
                    document.location.href = rootUrl + 'Project/' + data.projectId;

                } else {
                    alert('Error: ' + data.error);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert('error: ' + textStatus + ': ' + errorThrown);
            }
        });

    });

    // save client
    $('#editClientForm').submit(function (e) {
        e.preventDefault();
        var client = new Object();

        client.Id = $('#editClientForm [name="Id"]').val();
        client.Name = $('#editClientForm [name="Name"]').val();
        client.ContactName = $('#editClientForm [name="ContactName"]').val();
        client.Email = $('#editClientForm [name="Email"]').val();
        client.Phone = $('#editClientForm [name="Phone"]').val();
        client.WebsiteUrl = $('#editClientForm [name="WebsiteUrl"]').val();
        client.SkypeId = $('#editClientForm [name="SkypeId"]').val();
        client.Notes = $('#editClientForm [name="Notes"]').val();
        client.Active = $('#editClientForm [name="Active"]').prop('checked');

        client.Address1 = $('#editClientForm [name="Address1"]').val();
        client.Address2 = $('#editClientForm [name="Address2"]').val();
        client.TownCity = $('#editClientForm [name="TownCity"]').val();
        client.CountyState = $('#editClientForm [name="CountyState"]').val();
        client.PostcodeZip = $('#editClientForm [name="PostcodeZip"]').val();
        client.CountryCode = $('#editClientForm [name="Country"]').val();

        // logo image (as base64)
        var files = $('#editClientLogoImageFile')[0].files;
        if (files.length > 0) {
            client.LogoImageBase64 = $('#editClientForm [name="LogoImageBase64"]').val();
        }

        // post
        $.ajax({
            type: 'POST',
            url: rootUrl + 'Clients/SaveClient',
            data: { client: client },
            dataType: 'json', //to parse string into JSON object,
            success: function (data) {
                if (data.result === 'ok') {
                    unsaved = false; // reset navigate away flag
                    //document.location.href = rootUrl + 'Dashboard/Project/' + data.projectId;
                    document.location.reload();

                } else {
                    alert('Error: ' + data.error);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert('error: ' + textStatus + ': ' + errorThrown);
            }
        });

    });

    // invite user
    $(document).on('click', '.invite-user-btn', function (e) {
        e.preventDefault();
        var btn = $(this);
        var clientId = btn.data("client-id");
        $('#inviteUserForm [name="ClientId"]').val(clientId);
        $('#inviteUserModal').modal({ backdrop: 'static' });
    }); 

    $('#inviteUserForm').submit(function (e) {
        e.preventDefault();
        var user = new Object();
        var clientId = $('#inviteUserForm [name="ClientId"]').val();
        user.ClientId = clientId;
        user.FirstName = $('#inviteUserForm [name="FirstName"]').val();
        user.LastName = $('#inviteUserForm [name="LastName"]').val();
        user.Email = $('#inviteUserForm [name="Email"]').val();

        // post
        $.ajax({
            type: 'POST',
            url: rootUrl + 'Account/InviteUser',
            data: { user: user },
            dataType: 'json', //to parse string into JSON object,
            success: function (data) {
                if (data.result === 'ok') {
                    unsaved = false; // reset navigate away flag
                    $('#inviteUserModal').modal('hide'); // close modal
                    LoadUserDetails(clientId);
                    //document.location.href = rootUrl + 'Dashboard/Project/' + data.projectId;
                    //document.location.reload();

                } else {
                    alert('Error: ' + data.error);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert('error: ' + textStatus + ': ' + errorThrown);
            }
        });
    });

    // expand client - load users 
    $(document).on('click', '.client-expand-btn.collapsed', function (e) {
        e.preventDefault();
        var btn = $(this);
        var clientId = btn.data("client-id");
        LoadUserDetails(clientId);
    }); 

    //$('.component-footer').on('click', 'a', function (e) {
    //    e.preventDefault();
    //    var btn = $(this);
    //    var container = $(btn).closest('.component-footer');
    //    var detail = $(container).next('.component-detail');
    //    detail.slideToggle();
    //});

    // confirm quote sections
    $('.quote-section').on('click', '.confirm-quote-section-btn', function (e) {
        e.preventDefault();
        var quoteSectionId = $(this).data('quote-section-id');
        var container = $(this).closest('.quote-section');
        // post
        $.ajax({
            type: 'POST',
            url: rootUrl + 'Project/AcceptQuoteSection',
            data: { quoteSectionId: quoteSectionId },
            dataType: 'json', //to parse string into JSON object,
            success: function (data) {
                if (data.result === 'ok') {
                    container.find('.requires-action-wrapper').addClass('state-hidden');
                    container.find('.confirmed-wrapper').removeClass('state-hidden');
                    container.find('.expand-btn').trigger('click');
                    container.find('.quote-section-client-actions').fadeOut();
                } else {
                    alert('Error: ' + data.error);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert('error: ' + textStatus + ': ' + errorThrown);
            }
        });
    }); 

    // click proceed project 
    $('.quote-summary').on('click', '.project-proceed-btn', function (e) {
        e.preventDefault();
        var btn = $(this);
        var projectId = btn.data("project-id");
        // check all options confirmed
        $.ajax({
            type: 'POST',
            url: rootUrl + 'Project/CheckProjectQuoteSectionsConfirmed',
            data: { projectId: projectId },
            dataType: 'json', //to parse string into JSON object,
            success: function (data) {
                if (data.result === 'ok')
                {
                    if (data.confirmed === 'true') {
                        $('#confirm-notify').hide();
                        // popup
                        $('#acceptProjectModal').modal({ backdrop: 'static' });
                        var wrapper = document.getElementById("signature-pad");
                        var canvas = wrapper.querySelector("canvas");
                        signaturePad = new SignaturePad(canvas);
                    }
                    else {
                        $('#confirm-notify').slideDown();
                    }
                } else {
                    alert('Error: ' + data.error);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert('error: ' + textStatus + ': ' + errorThrown);
            }
        });
    });
    
    $('#acceptProjectModal').on('click', '#acceptProjectBtn', function (event) {
        event.preventDefault();
        if (signaturePad.isEmpty()){
            return;
        }
        var btn = $(this);
        var projectId = btn.data("project-id");
        // get signature
        var wrapper = document.getElementById("signature-pad");
        var canvas = wrapper.querySelector("canvas");
        var dataUrl = canvas.toDataURL();
        $.ajax({
            type: 'POST',
            url: rootUrl + 'Project/AcceptProject',
            data: { projectId: projectId, signature: dataUrl },
            dataType: 'json', //to parse string into JSON object,
            success: function (data) {
                if (data.result === 'ok')
                {
                    // hide this popup
                    $('#acceptProjectModal').modal('hide'); 
                    // hide start button
                    $('.quote-summary-actions').hide();
                    // show payment popup
                    ShowPaymentPopup(projectId);
                } else {
                    alert('Error: ' + data.error);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert('error: ' + textStatus + ': ' + errorThrown);
            }
        });
    });

    $('#acceptProjectModal').on('click', '#clearSignature', function (event) {
        event.preventDefault();
        signaturePad.clear();
    });

    $('.expandable-item-body').on('click', '.delete-client', function (event) {
        event.preventDefault();
        if (confirm("Confirm deleting this Client, Projects and Users?")) {
            var clientId = $(this).data("client-id");
            $.ajax({
                type: 'POST',
                url: rootUrl + 'Clients/SetClientActive',
                data: { clientId: clientId, active: false },
                dataType: 'json', //to parse string into JSON object,
                success: function (data) {
                    if (data.result === 'ok')
                    {
                        document.location.reload();
                    } else {
                        alert('Error: ' + data.error);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    alert('error: ' + textStatus + ': ' + errorThrown);
                }
            });
        }
    });

    $('.expandable-item-body').on('click', '.user-activity-log-btn', function (event) {
        event.preventDefault();
        var userId = $(this).data("user-id");
        $.ajax({
            type: 'POST',
            url: rootUrl + 'Account/GetUserActivityLog',
            data: { userId: userId },
            dataType: 'json', //to parse string into JSON object,
            success: function (data) {
                if (data.result === 'ok')
                {
                    // populate modal and show it
                    var loader = $('#userActivityTableLoader');
                    loader.empty();
                    var dataTable = "<table class='table table-striped table-sm'><thead class='thead-dark'><tr><th>Date/Time</th><th>Activity</th></thead><tbody>";
                    for (i = 0; i < data.log.length; i++) {
                        dataTable += "<tr><td>" + data.log[i].TimestampString + "</td><td>" + data.log[i].ActionString + "</td>";
                    }
                    dataTable += "</tbody></table>";
                    loader.append(dataTable);
                    $('#userActivityLogUsername').text(data.userName);
                     $('#userActivityLogModal').modal({ backdrop: 'static' });
                } else {
                    alert('Error: ' + data.error);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert('error: ' + textStatus + ': ' + errorThrown);
            }
        });  
    });
});


function ShowPaymentPopup(projectId) {
    $.ajax({
        type: 'POST',
        url: rootUrl + 'Project/GetPaymentInfo',
        data: { projectId: projectId },
        dataType: 'json', //to parse string into JSON object,
        success: function (data) {
            if (data.result === 'ok')
            {
                $('#paymentModal').modal({ backdrop: 'static' });
                $('#paymentRef').text(data.paymentRef);
                $('#paymentAmountDue').text(data.paymentAmountDue);
            } else {
                alert('Error: ' + data.error);
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            alert('error: ' + textStatus + ': ' + errorThrown);
        }
    });
}

function LoadUserDetails(clientId) {
    $.post(rootUrl + 'Account/GetUsersForClient', { clientId: clientId }, function (rsp) {
        for (i = 0; i < rsp.userList.length; i++) {
            var status = "Unknown";
            switch (rsp.userList[i].InviteStatus) {
                case 0:
                    {
                        status = "Invited";
                        break;
                    }
                case 1:
                    {
                        status = "Accepted";
                        break;
                    }
            }
            $('#userDetailsLoader-' + clientId).append('<div id="userDetails-' + rsp.userList[i].Id + '"></div>');
            $('#userDetails-' + rsp.userList[i].Id).loadTemplate($("#userDetailsTemplate"),
                {
                    Id: rsp.userList[i].Id,
                    Email: rsp.userList[i].Email,
                    Name: rsp.userList[i].FirstName + " " + rsp.userList[i].LastName,
                    Status: status,
                    DataTarget: "userBody-" + rsp.userList[i].Id,
                    DataTargetId: "#userBody-" + rsp.userList[i].Id,
                    InviteDate: moment(rsp.userList[i].Invited).fromNow()
            });
        }
    });
}

function LoadNavProjectList(clientId) {
    var $projectDropdown = $('#navbarProjectDropdown');
    $projectDropdown.find('.dropdown-toggle').html('<span class="text-muted">Select a Project</span>');

    var $projectDropdownMenu = $projectDropdown.find('.dropdown-menu');
    $projectDropdownMenu.empty();

    $.get(rootUrl + 'Project/GetProjectsForClient', { id: clientId }, function (response) {
        if (response.result === 'ok') {
            $.each(response.data, function () {
                var $link = $('<a class="dropdown-item">').attr('href', this.Url).text(this.Name);
                $projectDropdownMenu.append($link);
            });
            if (userAdmin) {
                // Add extra entry to dropdown for adding new project using popup
                var $link = $('<a class="dropdown-item admin-add-proj-btn" href="#" data-client-id="' + clientId  + '"><i class="fas fa-plus"></i> Add Project</a>');
                $projectDropdownMenu.append($link);
            }
        } else if (response.result === 'fail') {
            alert('Could not get list of projects: ' + response.error);
        }
    }, 'json');
}

function CountdownTimer(dateString, $container) {
    var end = new Date(dateString);

    var secondMs = 1000;
    var minuteMs = secondMs * 60;
    var hourMs = minuteMs * 60;
    var dayMs = hourMs * 24;
    var timer;

    function showRemaining() {
        var now = new Date();
        var msRemaining = end - now;
        if (msRemaining < 0) {
            clearInterval(timer);
            $container.text('Expired');
            return;
        }
        var days = Math.floor(msRemaining / dayMs);
        var hours = Math.floor((msRemaining % dayMs) / hourMs);
        var minutes = Math.floor((msRemaining % hourMs) / minuteMs);
        var seconds = Math.floor((msRemaining % minuteMs) / secondMs);
            
        $container.text(days + ' day' + (days !== 1 ? 's' : '') + ' '
            + (hours < 10 ? '0' : '') + hours + ':'
            + (minutes < 10 ? '0' : '') + minutes + ':'
            + (seconds < 10 ? '0' : '') + seconds);
    }

    showRemaining();
    timer = setInterval(showRemaining, 1000);
}

function Base64ImageFilePicker($fileInput, $base64Input, $previewImg, $previewContainer) {
    $fileInput.off('change').change(function () {
        var file = this.files[0];

        if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png') {
            if ($previewImg) $previewImg.attr('src', 'noimage');
            if ($previewContainer) $previewContainer.hide();
            alert('Invalid file format, only JPG/JPEG and PNG images are supported');
            return false;
        }

        var fileReader = new FileReader();
        fileReader.onload = function (e) {
            var base64DataString = e.target.result;
            if ($previewImg) $previewImg.attr('src', base64DataString);
            if ($previewContainer) $previewContainer.show();
            $base64Input.val(base64DataString);
        };
        fileReader.readAsDataURL(file);
    });
}